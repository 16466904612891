import { formatDiagnosticsWithColorAndContext } from "typescript";

// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Edmond",
  middleName: "",
  lastName: "De Ruyter",
  message: " Aspiring Student Business Management - Marketing. Passionate about digital marketing and product & brand management",
  icons: [
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/edmond-de-ruyter/",
    },
    {
      image: "fa-facebook",
      url: "https://www.facebook.com/edmond.deruyter/",
    },
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/edmond_de_ruyter/",
    },
  ],
};

// ABOUT SECTION

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/Edmond.png"),
  imageSize: 375,
  message:
    "My name is Edmond De Ruyter, an aspiring student Business Management - Marketing. Passionate about digital marketing and product & brand management.",
  message2:"I'm 22 years old and live near Hasselt, Belgium. I'm currently doing my internship in a sports marketing agency called Be A Legend. After this internship, I will graduate in June 2022 for the bachelor business management with a specialization in marketing.",
  resume: require("../editable-stuff/resume.pdf"),
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: false,
  heading: "Recent Projects",
  gitHubUsername: "skillybilly",
  reposLength: 4,
  specificRepos: [],
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "Dutch", value: 100 },
    { name: "English", value: 95 },
    { name: "French", value: 60 },
    { name: "Digital Marketing", value: 70 },
    { name: "Copywritting", value: 80 },
    { name: "Graphic Design", value: 65 },
    { name: "Video Editing", value: 70 },
    { name: "Intercultural Communication", value: 80 },
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 80 },
    { name: "Collaboration", value: 90 },
    { name: "Positivity", value: 75 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 75 },
    { name: "Empathy", value: 95 },
    { name: "Organization", value: 70 },
    { name: "Creativity", value: 90 },
  ],
};

// Leadership SECTION
const leadership = {
  show: true,
  heading: "Accomplishments",
  message:
    "Since I value self-development and I'm eager to extend my knowledge, I obtained the Google Garage certificate on the Fundamentals Of Digital Marketing. Not long after that I passed the exam concerning Inbound Sales at HubSpot Academy",
  message3: "Throughout my first and second year of the bachelor business management - marketing, I was given enough knowledge and experience to understand the true essence of marketing. Having passed all courses in the intended period of two years, I'm currently in my third year wherein I finalize my skills and knowledge. In February 2022, I started my internship in a sports marketing agency. The content I created there can be found in my portfolio.",
  images: [
    { 
      img: require("../editable-stuff/Certificate.png"), 
      label: " ", 
      paragraph: " "
    },
    { 
      img: require("../editable-stuff/CertificateSales.png"), 
      label: " ", 
      paragraph: " "
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "I'm currently working as an intern for a sports marketing agency! If you want to discuss a potential cooperation in my upcoming career, if you have any questions, or if you just want to say hi, please feel free to contact me using",
  email: "the contact section",
};

// timeline SECTION
const timeline = {
  show: true,
};

//portfolio SECTION
const portfolio = {
  show: true,
};

// contact SECTION
const contact = {
  show: true,
};

// Blog SECTION
const blog = {
  show: false,
  heading: "Follow my profesional life via my blog",
};


export { navBar, mainBody, about, repos, leadership, skills, getInTouch, timeline, portfolio, contact };
